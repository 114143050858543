
	frappe.templates['contact_list'] = `<div class="clearfix"></div>
{% for(var i=0, l=contact_list.length; i<l; i++) { %}
	<div class="address-box">
		<p class="h6 flex align-center">
			{%= contact_list[i].first_name %} {%= contact_list[i].last_name %}
			{% if(contact_list[i].is_primary_contact) { %}
				<span class="text-muted">&nbsp;({%= __("Primary") %})</span>
			{% } %}
			{% if(contact_list[i].designation){ %}
			 <span class="text-muted">&ndash; {%= contact_list[i].designation %}</span>
			{% } %}
			<a href="/app/Form/Contact/{%= encodeURIComponent(contact_list[i].name) %}"
				class="btn btn-xs btn-default ml-auto">
				{%= __("Edit") %}
			</a>
		</p>
		{% if (contact_list[i].phones || contact_list[i].email_ids) { %}
		<p>
			{% if(contact_list[i].phone) { %}
				{%= __("Phone") %}: {%= contact_list[i].phone %}<span class="text-muted"> ({%= __("Primary") %})</span><br>
			{% endif %}
			{% if(contact_list[i].mobile_no) { %}
				{%= __("Mobile No") %}: {%= contact_list[i].mobile_no %}<span class="text-muted"> ({%= __("Primary") %})</span><br>
			{% endif %}
			{% if(contact_list[i].phone_nos) { %}
				{% for(var j=0, k=contact_list[i].phone_nos.length; j<k; j++) { %}
					{%= __("Phone") %}: {%= contact_list[i].phone_nos[j].phone %}<br>
				{% } %}
			{% endif %}
		</p>
		<p>
			{% if(contact_list[i].email_id) { %}
				{%= __("Email") %}: {%= contact_list[i].email_id %}<span class="text-muted"> ({%= __("Primary") %})</span><br>
			{% endif %}
			{% if(contact_list[i].email_ids) { %}
				{% for(var j=0, k=contact_list[i].email_ids.length; j<k; j++) { %}
					{%= __("Email") %}: {%= contact_list[i].email_ids[j].email_id %}<br>
				{% } %}
			{% endif %}
		</p>
		{% endif %}
		<p>
		{% if (contact_list[i].address) { %}
			{%= __("Address") %}: {%= contact_list[i].address %}<br>
		{% endif %}
		</p>
	</div>
{% } %}
{% if(!contact_list.length) { %}
<p class="text-muted small">{%= __("No contacts added yet.") %}</p>
{% } %}
<p><button class="btn btn-xs btn-default btn-contact">
	{{ __("New Contact") }}</button>
</p>`;

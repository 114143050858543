
	frappe.templates['form_sidebar'] = `<ul class="list-unstyled sidebar-menu user-actions hidden"></ul>
<ul class="list-unstyled sidebar-menu sidebar-image-section hide">
	<li class="sidebar-image-wrapper">
		<img class="sidebar-image">
		<div class="sidebar-standard-image">
			<div class="standard-image"></div>
		</div>
		<div class="sidebar-image-actions">
			<div class="dropdown">
				<a href="#" class="dropdown-toggle" data-toggle="dropdown">{{ __("Change") }}</a>
				<div class="dropdown-menu" role="menu">
					<a class="dropdown-item sidebar-image-change">{{ __("Upload") }}</a>
					<a class="dropdown-item sidebar-image-remove">{{ __("Remove") }}</a>
				</div>
			</div>
		</div>
	</li>
</ul>
{% if frm.meta.beta %}
<div class="sidebar-menu">
	<p><label class="indicator-pill yellow" title="{{ __("This feature is brand new and still experimental") }}">{{ __("Experimental") }}</label></p>
	<p><a class="small text-muted" href="https://github.com/frappe/{{ frappe.boot.module_app[frappe.scrub(frm.meta.module)] }}/issues/new"
			target="_blank">
		{{ __("Click here to post bugs and suggestions") }}</a></p>

</div>
{% endif %}
<ul class="list-unstyled sidebar-menu sidebar-rating hide">
	<li style="position: relative;">
		<a class="strong badge-hover">
			<span>{%= __("Feedback") %}</span>
		</a>
	</li>
	<li class="rating-icons"></li>
</ul>
<ul class="list-unstyled sidebar-menu hidden">
	{% if (frappe.help.has_help(doctype)) { %}
	<li><a class="help-link list-link" data-doctype="{{ doctype }}">{{ __("Help") }}</a></li>
	{% } %}
</ul>
<ul class="list-unstyled sidebar-menu form-assignments">
	<li class="sidebar-label">
		<svg class="icon icon-sm"><use href="#icon-assign"></use></svg>
		{%= __("Assigned To") %}
	</li>
	<li class="flex flex-wrap">
		<span class="assignments"></span>
		<button class="text-muted btn btn-default icon-btn add-assignment-btn">
			<svg class="icon icon-sm"><use href="#icon-add"></use></svg>
		</button>
	</li>
</ul>
<ul class="list-unstyled sidebar-menu form-attachments">
	<li class="sidebar-label attachments-label">
		<svg class="icon icon-sm"><use href="#icon-attachment"></use></svg>
		{%= __("Attachments") %}
	</li>
	<li class="add-attachment-btn">
		<button class="data-pill btn">
			<span class="pill-label ellipsis">
				{%= __("Attach File") %}
			</span>
			<svg class="icon icon-sm">
				<use href="#icon-add"></use>
			</svg>
		</button>
	</li>
</ul>
<ul class="list-unstyled sidebar-menu form-reviews">
	<li class="sidebar-label reviews-label">
		<svg class="icon icon-sm"><use href="#icon-review"></use></svg>
		{%= __("Reviews") %}
	</li>
	<li class="reviews">
		<button class="add-review-btn text-muted btn btn-default icon-btn">
			<svg class="icon icon-sm"><use href="#icon-add"></use></svg>
		</button>
	</li>
</ul>
<ul class="list-unstyled sidebar-menu form-shared">
	<li class="sidebar-label">
		<svg class="icon icon-sm"><use href="#icon-share"></use></svg>
		{%= __("Shared With") %}
	</li>
	<li class="flex flex-wrap">
		<span class="shares"></span>
		<button class="share-doc-btn text-muted btn btn-default icon-btn">
			<svg class="icon icon-sm"><use href="#icon-add"></use></svg>
		</button>
	</li>
</ul>
<ul class="list-unstyled sidebar-menu followed-by-section">
	<li class="sidebar-label followed-by-label hidden">
		<svg class="icon icon-sm">
			<use href="#icon-link-url" class="like-icon"></use>
		</svg>
		{%= __("Followed by") %}
	</li>
	<li class="followed-by"></li>
	<li class="document-follow">
		<a class="badge-hover follow-document-link hidden">
			{%= __("Follow") %}
		</a>
		<a class="badge-hover unfollow-document-link hidden">
			{%= __("Unfollow") %}
		</a>
	</li>
</ul>
<ul class="list-unstyled sidebar-menu form-tags">
	<li class="sidebar-label tags-label">
		<svg class="icon icon-sm"><use href="#icon-tag"></use></svg>
		{%= __("Tags") %}
	</li>
</ul>
<ul class="list-unstyled sidebar-menu">
	<a><li class="auto-repeat-status"><li></a>
</ul>
<ul class="list-unstyled sidebar-menu form-sidebar-stats">
	<li class="flex">
		<div class="form-stats">
			<span class="form-stats-likes">
				<span class="liked-by like-action">
					<svg class="icon icon-sm">
						<use href="#icon-heart" class="like-icon"></use>
					</svg>
					<span class="like-count"></span>
				</span>
			</span>
			<span class="mx-1">·</span>
			<a class="comments">
				<svg class="icon icon-sm">
					<use href="#icon-comment" class="comment-icon"></use>
				</svg>
				<span class="comments-count"></span>
			</a>
		</div>
		<a class="form-follow text-sm">
			Follow
		</a>
	</li>
</ul>
<hr>
<!-- <ul class="list-unstyled sidebar-menu">
	<li class="liked-by-parent">
		<span class="liked-by like-action">
			<svg class="icon icon-sm">
				<use href="#icon-heart" class="like-icon"></use>
			</svg>
			<span class="likes-count"></span>
		</span>
	</li>
</ul> -->
<ul class="list-unstyled sidebar-menu text-muted">
	<li class="pageview-count"></li>
	<li class="modified-by"></li>
	<li class="created-by"></li>
</ul>
{% if(frappe.get_form_sidebar_extension) { %}
	{{ frappe.get_form_sidebar_extension() }}
{% } %}
`;
